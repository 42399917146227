import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MetaResponse} from '../types/system';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {HelpersService} from './helpers.service';
import {KeycloakService} from "keycloak-angular";
import {SentryService} from "./sentry.service";
import {VersionService} from "./version.service";
import {UmbrellaService} from "./umbrella.service";
import {GtmService} from "./gtm.service";

declare let $crisp;

@Injectable()
export class AuthService {
  private baseUrl = environment.ndcApiEndpointV2 + 'agent/auth/';
  public agencyImgUrl: string;

  public isAuth$ = new BehaviorSubject(false);
  public isUserDataReceived = new Subject<boolean>();
  public isUserDataReceived$ = this.isUserDataReceived.asObservable();

  constructor(private http: HttpClient,
              private helpers: HelpersService,
              private ls: LocalStorageService,
              private keycloakService: KeycloakService,
              private sentryService: SentryService,
              private versionService: VersionService,
              private umbrellaService: UmbrellaService,
              private gtmService: GtmService) {
  }

  login(loginForm: any): Promise<MetaResponse> {
    return this.http.post<MetaResponse>(`${this.baseUrl}login`, JSON.stringify(loginForm))
      .toPromise();
  }

  logout() {
    this.isAuth$.next(false);
    this.ls.resetStorage();
    this.ls.resetHrefLink();
    this.redirectToLogout();
  }

  redirectToLogout(): void {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    if (keycloakInstance && keycloakInstance['endpoints'] && keycloakInstance['endpoints'].logout) {
      const logoutUrl = keycloakInstance['endpoints'].logout()
        + '?id_token_hint=' + keycloakInstance.idToken
        + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.origin);
      window.location.href = logoutUrl;
    } else {
      this.keycloakService.clearToken();
      this.keycloakService.logout();
    }
  }

  postForgotPassword(form: any): Promise<MetaResponse> {
    return this.http.post<MetaResponse>(`${this.baseUrl}forgot-password`, JSON.stringify(form))
      .toPromise();
  }

  postResetPassword(form: any): Promise<MetaResponse> {
    return this.http.post<MetaResponse>(`${this.baseUrl}reset-password`, JSON.stringify(form))
      .toPromise();
  }

  postSignupFromEmail(form: any): Promise<MetaResponse> {
    return this.http.post<MetaResponse>(`${this.baseUrl}signup-from-email`, JSON.stringify(form))
      .toPromise();
  }

  updateUserData(response: any) {
    const storedIsSuperagency = (this.ls.isSuperagency === 'true');
    if (response.data.is_superagency !== storedIsSuperagency) {
      this.ls.isSuperagency = response.data.is_superagency;
      let event = new Event('superagencyCheck');
      window.dispatchEvent(event);
    }
    this.ls.agency = response.data.agency;
    this.ls.agencyID = response.data.agency_id;
    this.ls.email = response.data.email;
    this.ls.consumer = response.data.consumer;
    this.ls.isSubagenciesAvailable = response.data.is_subagencies_available;
    this.ls.userType = 'agent';
    this.ls.consumerCode = response.data.consumer_code;
    this.ls.role = response.data.role;
    this.agencyImgUrl = response.data.agency_image_url;
    this.helpers.defaultCurrency = response.data.currency_code || 'EUR';
    this.helpers.isSuperUser = this.ls.role === 'manager' && this.ls.isSuperagency;
    if (response.data.agent_id) {
      this.ls.agentID = response.data.agent_id;
    }
    if (response.data.umbrella_client_id) {
      this.ls.setClientId(response.data.umbrella_client_id);
    }
    this.ls.profilesType = response.data.profiles_type;

    if (this.ls.profilesType === 'umbrella') {
      this.umbrellaService.getBaseUrl().toPromise().then();
    }

    this.gtmService.addData({'user_id': this.ls.email});

    const userContext = {
      profiles_type: response.data.profiles_type,
      consumer: response.data.consumer,
      agency: response.data.agency,
      user_type: 'agent',
      username: this.ls.email,
      version: this.versionService.version + (this.ls.appVersion ? ` (API Version: ${this.ls.appVersion})` : ''),
    };

    this.sentryService.setContext('agent', userContext);

    try {
      this.setBoldChatSettings(response);
    } catch (error) {
      if (this.sentryService) {
        this.sentryService.notify(error);
      }
    }
  }

  setBoldChatSettings(response: any) {
    window['boldChatSettings'] = {
      email: response.data.email,
      // cf_agencyid: response.data.agency,
      // agencyid: response.data.agency,
      // agencyId: response.data.agency,
      fields: {
        cf_agencyid: response.data.agency,
      }
    };
    if (typeof $crisp !== 'undefined' && (environment.name === 'prod' || environment.name === 'dev') && response?.data) {
      $crisp.push(["set", "user:email", [response.data.email]]);
      $crisp.push(["set", "user:nickname", [response.data.username]]);
      $crisp.push(["set", "user:company", [response.data.agency]]);
      $crisp.push(["set", "session:data", [[
        ["consumer", response.data.consumer_code],
        ["logged_username", response.data.username],
        ["logged_agency_id", response.data.agency],
      ]]]);
    }
  }
}
