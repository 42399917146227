import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { environment } from "../environments/environment";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NotificationService } from "./shared/services/notification.service";
import { LocalStorageService } from './shared/services/local-storage.service';
import { HelpersService } from "./shared/services/helpers.service";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { ConfigService } from './shared/services/config.service';
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/angular";
import { Replay } from "@sentry/replay";
import { FilterLoaderService } from "./shared/services/filter-loader.service";
import {VersionService} from "./shared/services/version.service";
import {FakeError} from "./shared/classes/fake-error";
import {PlatformLocation} from "@angular/common";
import { LanguageService } from './shared/services/language.service';
import { filter } from 'rxjs/operators';

declare let ActiveXObject: any;
declare let window: any;
declare function appInitGTM(gtmId);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbModalConfig, NgbModal]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('customNotification') customNotificationTpl;
  @ViewChild('outdatedBrowserModal') outdatedBrowserModalTemplate: TemplateRef<any>;

  title = 'app';
  isChromiumBasedBrowser = true;
  isBrowserSupported = true;
  isGodModeEnabled = false;

  frontendSelectedDependency = environment.default;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.ctrlKey && event.code === 'KeyE') {
      if (environment.name !== 'prod') {
        this.ls.warningsDisabled = !this.ls.warningsDisabled;
      }
    }
    if (event.shiftKey && event.ctrlKey && event.code === 'KeyU' && environment.name === 'staging') {
      throw new FakeError('Fake Error caused by hotkeys');
    }
    if (event.shiftKey && event.ctrlKey && event.code === 'KeyK' && this.helpers.isSuperUser) {
      this.isGodModeEnabled = !this.isGodModeEnabled;
      this.helpers.setGodModeData(true, this.isGodModeEnabled);
    }
  }

  constructor(
    private _notificationSvc: NotificationService,
    public ls: LocalStorageService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private helperService: HelpersService,
    private modalService: NgbModal,
    private config: ConfigService,
    public loaderService: FilterLoaderService,
    public versionService: VersionService,
    public helpers: HelpersService,
    private platformLocation: PlatformLocation,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    const lang = this.languageService.getCurrentLanguage();
    this.languageService.setLanguage(lang);

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this._notificationSvc.clearNotifications();
      }
      this.frontendSelectedDependency.showFooter = !router.url.includes('/maintenance');
    });

    platformLocation.onPopState(() => this.modalService.dismissAll());
    this.isChromiumBasedBrowser = helperService.isChromiumBasedBrowser();

    // if (environment.name !== 'dev') {
      if (this.config.KEYS.gtmId) {
        // appInitGTM(this.config.KEYS.gtmId);
      }
      if (this.config.KEYS.oldGtmId) {
        // appInitGTM(this.config.KEYS.oldGtmId);
      }
    // }

    if (!this.getCookie('cookieconsent_status')) {
      setTimeout(() => {
        let cookieBanner = document.getElementsByClassName('cc-message')[0] as HTMLElement;
        if (cookieBanner) {
          cookieBanner.style.flex = 'none';
        }
        }, 500);
    }
  }

  ngOnInit() {
    this.detectExtensionMode();
    this.checkBrowserMode();
    this.isBrowserSupported = this.helpers.isBrowserSupported();
    this.initServices();

    this.checkDefaultFEDependency();

    this.checkIfWarningsAreEnabledOnProd();
  }

  private detectExtensionMode(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const isSearchPage = this.router.url.includes('/search');

      if (isSearchPage) {
        this.route.queryParams.subscribe(params => {
          this.ls.isExtensionMode = params['is_extension_mode'] !== undefined;
        });
      }
    });
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  ngAfterViewInit(): void {
    const script = document.createElement("script");
    script.src = 'https://support.airgateway.com/chatwidget-api/widget/v1/a864efb5-3b19-42ee-856c-fb45605d6593';
    document.head.appendChild(script);
    
    if (!this.isChromiumBasedBrowser && !this.ls.skipUsingChromeBrowser) {
      setTimeout(() => this.modalService.open(this.outdatedBrowserModalTemplate), 100);
    }
  }

  continueWithoutChrome() {
    this.ls.setSkipUsingChromeBrowser();
  }

  checkDefaultFEDependency() {
    const subDomainName = window.location.hostname;
    const environmentSubDomain = environment.frontendDependencies.find(item => item.subDomain === subDomainName);
    if (environmentSubDomain) {
      this.frontendSelectedDependency = environmentSubDomain;
      if (!this.frontendSelectedDependency.logoName) {
        this.frontendSelectedDependency.logoName = environment.default.logoName;
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private initServices() {
    if (environment.name !== 'dev') {
      if (this.config.KEYS.sentryDSN) {
        Sentry.init({
          dsn: this.config.KEYS.sentryDSN,
          // This sets the sample rate to be 50%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.005,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 0.005,
          environment: environment.name,
          integrations: [
            new Replay({
              maskAllText: false,
              maskAllInputs: false
            }),
            new BrowserTracing({
              tracePropagationTargets: [environment.ndcApiEndpoint],
              routingInstrumentation: Sentry.routingInstrumentation,
            }),
          ],
          tracesSampleRate: 0.01,
          ignoreErrors: [
            'Non-Error exception captured',
            'NetworkError',
            /network error/,
            /AbortError/,
            /Request aborted/,
          ]
        });
      }
    }
  }

  checkBrowserMode() {
    let that = this;
    this.helpers.checkPrivateMode().then(function (isPrivate) {
      that.helpers.isPrivateMode = isPrivate;
    });
  }

  checkIfWarningsAreEnabledOnProd() {
    if (environment.name === 'prod' && this.ls.warningsDisabled) {
      this.ls.warningsDisabled = false;
    }
  }

  refresh(): void {
    window.location.reload();
  }
}
